// 组件
import importImg from '@/components/importImg.vue'
// 接口
import * as shopItemLabelsApi from '@/services/shop-item-labels.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import constant from '@/utils/constant.js'
import verifyRules from '@/utils/verify-rules/index.js'
import loading from '@/utils/loading.json'

export default {
  components: {
    importImg
  },
  data () {
    return {
      dataDictionary,
      constant,
      verifyRules: verifyRules(this),
      mainForm: {
        data: {
          name: null,
          name_en: null,
          remote_image_url: null
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    handleMainFormImgChange (fileData) {
      const _this = this
      if (fileData && fileData.length > 0) {
        _this.mainForm.data.remote_image_url = fileData[0].url
      } else {
        _this.mainForm.data.remote_image_url = null
      }
    },
    handleMainFormCancelClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          let requestParams = {}
          Object.assign(requestParams, _this.mainForm.data)
          requestParams = {
            shop_item_label: {
              ...requestParams
            }
          }
          _this.setGlobalLoading(true)
          shopItemLabelsApi.postShopItemLabels(requestParams).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.push({
                name: 'goodsManagerCommonLabelsList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          }).catch(() => _this.setGlobalLoading(false))
        } else {
          this.$message.error(this.$t('message.formIsRequire'))
          return false
        }
      })
    }
  }
}
