/**
 * 商品标签相关接口
 */
import request from '@/utils/request'

export const postShopItemLabels = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_item_labels',
    data
  })
}

export const putShopItemLabels = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_item_labels/${id}`,
    data
  })
}

export const deleteShopItemLabels = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_item_labels/${id}`
  })
}

export const getShopItemSimpleList = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_item_labels/shop_item_simple_list'
  })
}

export const postShopItemLabelsIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_item_labels/${id}/preview`
  })
}
